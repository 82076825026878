<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
        <el-form-item label="来源">
          <el-select
            v-model="queryForm.type"
            placeholder="请选择"
            clearable
            width="100px"
            style="width:150px"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in typeList"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="水平">
          <el-select
            v-model="queryForm.ability"
            placeholder="请选择"
            clearable
            width="100px"
            style="width:150px"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in abilityList"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="目的">
          <el-select
            v-model="queryForm.desc"
            placeholder="请选择"
            clearable
            width="100px"
            style="width:150px"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in descList"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否报班">
          <el-select
            v-model="queryForm.is_sign"
            placeholder="请选择"
            clearable
            width="100px"
            style="width:150px"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in isSignList"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系状态">
          <el-select
            v-model="queryForm.opAction"
            placeholder="请选择"
            clearable
            width="100px"
            style="width:150px"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in opActionArr"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="手机">
          <el-input
            placeholder="请输入手机号"
            v-model.trim="queryForm.phone"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="时间">
          <el-date-picker
            type="datetimerange"
            v-model="times"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="sourceUserList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column prop="id" label="ID" width="55" align="center"></el-table-column>
        <el-table-column label="来源" prop="type" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.type == 1">恰学日语</div>
            <div v-else-if="scope.row.type == 2">恰学韩语</div>
            <div v-else-if="scope.row.type == 3">恰学法语</div>
          </template>
        </el-table-column>
        <el-table-column label="用户手机号" prop="user_phone" align="center"></el-table-column>
        <el-table-column label="水平" prop="ability" align="center"></el-table-column>
        <el-table-column label="年龄" prop="level" align="center"></el-table-column>
        <el-table-column label="目的" prop="desc" align="center"></el-table-column>
        <el-table-column label="是否需要报班" prop="is_sign" align="center"></el-table-column>
        <el-table-column label="时间" prop="create_time" align="center"></el-table-column>
        <el-table-column label="是否联系" prop="op_action" align="center">
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.op_action"
              @change="changeOpAction(scope.row)"
            >
              <el-option
                :label="item.label"
                :value="item.value"
                v-for="item in opActionArr"
                :key="item.value"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <div class="pagination">
        <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.page"
        :page-sizes="[10, 30, 50]"
        :page-size="queryForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>
    </el-card>
  </div>
</template>

<script>
import {
  sourceUserList,
  editSourceUserList
} from "@/api/userApi";
export default {
  data() {
    return {
      queryForm: {
        page: 1,
        limit: 10,
        start_time: null,
        end_time: null,
        // phone: null,
        type: null,
        ability: "",
        desc: "",
        is_sign: "",
        opAction: "",
      },
      total: 0,
      times: null,
      sourceUserList: [],
      typeList: [
        {
          value: 1,
          label: "恰学日语"
        },
        {
          value: 2,
          label: "恰学韩语"
        },
        {
          value: 3,
          label: "恰学法语"
        },
      ],
      abilityList: [
        {
          value: "0基础",
          label: "0基础"
        },
        {
          value: "稍微了解",
          label: "稍微了解"
        },
        {
          value: "有一定基础",
          label: "有一定基础"
        },
      ],
      descList: [
        {
          value: "兴趣",
          label: "兴趣"
        },
        {
          value: "工作需要",
          label: "工作需要"
        },
        {
          value: "考研",
          label: "考研"
        },
        {
          value: "留学",
          label: "留学"
        },
        {
          value: "高考",
          label: "高考"
        },
        {
          value: "其他",
          label: "其他"
        },
      ],
      isSignList: [
        {
          value: "正在考虑",
          label: "正在考虑"
        },
        {
          value: "有过想法",
          label: "有过想法"
        },
        {
          value: "没有想过",
          label: "没有想过"
        },
      ],
      opActionArr: [
        {
          value: "未联系",
          label: "未联系"
        },
        {
          value: "成功联系",
          label: "成功联系"
        },
        {
          value: "已成单",
          label: "已成单"
        },
        {
          value: "未成功联系",
          label: "未成功联系"
        },
        {
          value: "待回访",
          label: "待回访"
        },
        {
          value: "明确拒绝",
          label: "明确拒绝"
        },
      ]
    };
  },
  created() {
    this.getSourceUserList()
  },
  methods: {
    // 获取意向用户列表
    getSourceUserList(){
      sourceUserList(this.queryForm).then(res=>{
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.sourceUserList = res.data.data;
        this.total = res.data.total;
      })
    },
    // 修改意向用户列表
    changeOpAction(row){
      editSourceUserList({id:row.id,op_action:row.op_action}).then(res=>{
        if (res.code == 1) {
          this.$message.success(res.msg);
          this.getSourceUserList();
        }else{
          this.$message.error(res.msg);
          return;
        }
      })
    },
    //  查询
    toQuery() {
      this.queryForm.page = 1;
      if (this.times) {
        // this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
        // this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
        this.queryForm.start_time = this.times[0].getFullYear()+ '-' +(this.times[0].getMonth() + 1) + '-' + this.times[0].getDate() 
	      + ' ' + this.checkTime(this.times[0].getHours()) + ':' + this.checkTime(this.times[0].getMinutes()) + ':' + 
        this.checkTime(this.times[0].getSeconds());
        this.queryForm.end_time = this.times[1].getFullYear()+ '-' +(this.times[1].getMonth() + 1) + '-' + this.times[1].getDate() 
	      + ' ' + this.checkTime(this.times[1].getHours()) + ':' + this.checkTime(this.times[1].getMinutes()) + ':' + 
        this.checkTime(this.times[1].getSeconds());
      } else if (this.times == null) {
        this.queryForm.start_time = null;
        this.queryForm.end_time = null;
      }
      this.getSourceUserList();
    },
    //日期格式化
    checkTime(i) {//时间
      if (i < 10) {
        i = "0" + i
      }
      return i;
    },
    handleSizeChange(size) {
      this.queryForm.limit = size;
      this.getSourceUserList();
    },
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.getSourceUserList();
    },
  },
};
</script>

<style scoped lang="less">
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}
::v-deep .ql-editor {
  min-height: 400px;
}
.thumb_box {
  width: 100%;
  height: 100%;
  img {
    width: 120px;
    height: 200px;
  }
}

.tips {
  color: red;
}
::v-deep .el-upload--text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
